$(() => {
  // ページプラグインの埋め込みコードを返す。
  function pagePluginCode(w) {
    // 幅に応じて高さを変更する場合
    let h
    if (w > 600) {
      h = 560
    } else {
      h = 560
    }
    return '<div class="fb-page" data-href="https://www.facebook.com/ITCOkinawa/" data-tabs="timeline" data-width="' + w + '" data-height="' + h + '" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/ITCOkinawa/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/ITCOkinawa/">IT Career 沖縄</a></blockquote></div>'
  }

  // ページプラグインを追加する要素
  let facebookWrap = $('.sns__box--fb')
  let fbBeforeWidth = '' // 前回変更したときの幅
  let fbWidth = facebookWrap.width() // 今回変更する幅
  let fbTimer = false
  $(window).on('load resize', function () {
    if (fbTimer !== false) {
      clearTimeout(fbTimer)
    }
    fbTimer = setTimeout(function () {
      fbWidth = facebookWrap.width() // 変更後の幅を取得
      // 前回の幅から変更があった場合のみ処理
      // スマホだとスクロール時にリサイズが発生することがあるため
      if (fbWidth != fbBeforeWidth) {
        facebookWrap.html(pagePluginCode(fbWidth)) // ページプラグインのコード変更
        window.FB.XFBML.parse() // ページプラグインの再読み込み
        fbBeforeWidth = fbWidth // 今回変更分を保存しておく
      }
    }, 200)
  })

  function getSwiperElement(elementId) {
    return document.getElementById(elementId + '__post__wrapper')
  }

  function getOptions(elementId) {
    return {
      loop: true,
      centeredSlides: true,
      pagination: '#' + elementId + '__page.swiper-pagination',
      nextButton: '#' + elementId + '__next.swiper-button-next',
      prevButton: '#' + elementId + '__prev.swiper-button-prev',
      breakpoints: {
        bp_parm: {
          slidesPerView: 1,
          spaceBetween: 0
        }
      }
    }
  }

  function initSwiper(elementId) {
    let swiperElement = getSwiperElement(elementId)
    if (!swiperElement) {
      return
    }
    let swiperWrapper = swiperElement.getElementsByClassName('swiper-wrapper')
    let swiperSlide = swiperElement.getElementsByClassName('swiper-slide')
    let options = getOptions(elementId)
    let swiper = undefined
    let bp_parm = 600

    if (swiperElement) {
      let screenWidth = window.innerWidth
      if (screenWidth < bp_parm + 1 && swiper === undefined) {
        swiper = new Swiper('#' + elementId + '__post__wrapper', options)
      } else if (screenWidth > bp_parm && swiper !== undefined) {
        swiper.destroy()
        swiper = undefined
        for (let i = 0; i < swiperWrapper.length; i++) {
          swiperWrapper[i].removeAttribute('style')
        }
        for (let j = 0; j < swiperSlide.length; j++) {
          swiperSlide[j].removeAttribute('style')
        }
      }
    }
  }

  initSwiper('recruit')
  initSwiper('recommend')
  initSwiper('event')
  initSwiper('released_event')

  window.addEventListener('resize', function(){initSwiper('recruit')})
  window.addEventListener('resize', function(){initSwiper('recommend')})

  let event__picInner = new Swiper('.event__picInner', {
    slidesPerView: 1,
    loop: true,
    speed: 2000,
    autoplay: 2500,
    // autoplayDisableOnInteraction: false,
    pagination: '.swiper-pagination',
    paginationClickable: true,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    autoResize: true,
    calculateHeight: false
  })

  // Ticker
  $('.topic__inner').huvTicker()

  // 新着求人 tile
  $(window).on('load resize', function () {
    if (window.matchMedia('(max-width: 600px)').matches) {
      $('.recruit__post__ttl').tile(1)
    } else if (window.matchMedia('(max-width: 900px)').matches) {
      $('.recruit__post__ttl').tile(2)
    } else {
      $('.recruit__post__ttl').tile(4)
    }
  })

  // ITキャリア沖縄とは？ tile
  $(window).on('load resize', function () {
    if (window.matchMedia('(max-width: 600px)').matches) {
    } else if (window.matchMedia('(max-width: 900px)').matches) {
      $('.about__merit__ttl').tile(2)
    } else {
      $('.about__merit__ttl').tile()
    }
  })

  // ニュース・コラム
  $(".select__trigger").on("click", function () {
    if (window.matchMedia("(max-width: 900px)").matches) {
      $(this).next().stop().slideToggle()
      $(this).toggleClass('trigger_on')
    }
  })
  $(window).on('resize', function () {
    if (!window.matchMedia('(max-width: 900px)').matches) {
      $('.okinawaInfo__tab').css({
        'display': 'flex',
      })
    } else {
      $('.okinawaInfo__tab').css({
        'display': 'none',
      })
    }
  })

  let post_type = 'all'
  $('.okinawaInfo__tab a').on('click', function () {
    // .tabWrapper内のクラス監視
    if (!$('.okinawaInfo__post__wrap').children().hasClass('hide')) {
      let get_post_type = $(this).attr('href')
      // 取得したhrefの値とpost_typeの値を比較
      if (post_type !== get_post_type) {
        // post_typeの更新
        post_type = get_post_type
        // ajax発火
        $('.okinawaInfo__tab a').removeClass('on')
        $(this).addClass('on')

        $('.okinawaInfo__post__list').addClass('hide')
        setTimeout(() => {
          $('.okinawaInfo__post__item_switcher').hide()
          $('.okinawaInfo__post__item_switcher.' + post_type).show()
          $('.okinawaInfo__post__list').removeClass('hide')
        }, 500)
      }
    }

    return false
  })

  $('.okinawaInfo__post__item.all').show()
})
